<template>
  <div class="loginbox">
    <div class="back-icon">
      <van-icon
        color="#fff"
        name="arrow-left"
        @click="$router.replace('/login')"
      />
    </div>
    <div class="loginBody-image">
      <van-image  height="4rem" :src="appLogo" />
      <!-- <span class="spanTv">{{ appName }}</span> -->
    </div>
    <div class="loginBody-found">
      <van-tabs line-width="0px" line-height="0px" v-model="form.type">
        <van-tab :title="$t('register.phonereg')">
          <van-row>
            <van-form ref="phoneform">
                <van-field
                  v-model="form.username"
                  name="username"
                  clearable
                  :placeholder="$t('register.enterphone')"
				  class="newInput"
                  :rules="phonerules"
                >
                  <template #label>
					<span class="selectBlock">{{select_quhao}}
						<van-dropdown-menu active-color="#1989fa" class="menuStyle">
						  <!-- <van-dropdown-item v-model="lang" :options="option" @change="handelChange"/> -->
						  <van-dropdown-item id="item" ref="item">
							  <div class="list" :class="select_quhao==item.text?'selectItem':''" v-for="(item,index) in option" :key="item.id" @click="handelChange(item)">
								  <img class="image" :src="item.icon">
								  <span class="guojia">{{item[lang+'name']}}</span>
								  <span class="area">{{item.text}}</span>
							  </div>
						  </van-dropdown-item>
						</van-dropdown-menu>
					</span>
                    
                  </template>
                </van-field>
				<van-field
				    v-model="form.sms"
				    clearable
				    center
				    :placeholder="$t('forget.entercode')"
				    :rules="smsrules"
				    class="smsbtn"
				  >
					<template #left-icon>
					  <van-icon
					    class="iconfont yanzheng"
					    class-prefix="icon"
					    name="yanzhengma"
					  />
					</template>
				    <template #button>
				      <van-button
				        v-if="sendsmsbtn"
				        size="small"
				        @click.prevent="sendsms"
				        >{{ $t("forget.sendcode") }}</van-button
				      >
				      <van-button size="small" disabled v-else
				        >{{ sendsmstime }}S</van-button
				      >
				    </template>
				  </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  name="password"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="passrules"
				  class="newInput"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont mima"
                      class-prefix="icon"
                      name="ziyuanxhdpi"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.repassword"
                  :type="type"
                  name="repassword"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="repassrules"
				  class="newInput"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont mima"
                      class-prefix="icon"
                      name="ziyuanxhdpi"
                    />
                  </template>
                </van-field>
				<van-field
				  v-model="form.invite_code"
				  name="invite_code"
				  :placeholder="$t('PleaseEnterTheInvitationCode')"
				  :rules="invirules"
				  maxlength="6"
				  class="newInput"
				>
					<template #left-icon>
					  <van-icon
					    class="iconfont yaoqing"
					    class-prefix="icon"
					    name="zengjiarenwu"
					  />
					</template>
				</van-field>
            </van-form>
          </van-row>
        </van-tab>
        <van-tab :title="$t('register.emailreg')">
          <van-row>
            <van-form ref="emailform">
              <van-field
                v-model="form.username"
                name="username"
                clearable
                :placeholder="$t('register.enteremail')"
                :rules="emailrules"
                class="newInput"
              >
				<template #left-icon>
				  <van-icon
				    class="iconfont"
				    class-prefix="icon"
				    name="youxiang"
				  />
				</template>
              </van-field>
              <van-field
                  v-model="form.sms"
                  clearable
                  center
                  :placeholder="$t('forget.entercode')"
                  :rules="smsrules"
                  class="smsbtn"
                  style="background-color: #00000000;"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont yanzheng"
                      class-prefix="icon"
                      name="yanzhengma"
                    />
                  </template>
                  <template #button>
                    <van-button
                      v-if="sendemailbtn"
                      size="small"
                      @click.prevent="sendemail"
                      >{{ $t("forget.sendcode") }}</van-button
                    >
                    <van-button size="small" disabled v-else
                      >{{ sendemailtime }}S</van-button
                    >
                  </template>
                </van-field>
              <van-field
                v-model="form.password"
                :type="type"
                name="password"
                :right-icon="eye"
                :placeholder="$t('register.enterpassword')"
                @click-right-icon="openeye"
                :rules="passrules"
                class="newInput"
              >
				<template #left-icon>
				  <van-icon
				    class="iconfont mima"
				    class-prefix="icon"
				    name="ziyuanxhdpi"
				  />
				</template>
              </van-field>
              <van-field
                v-model="form.repassword"
                :type="type"
                name="repassword"
                :right-icon="eye"
                :placeholder="$t('register.enterpassword')"
                @click-right-icon="openeye"
                :rules="repassrules"
                class="newInput"
              >
				<template #left-icon>
				  <van-icon
				    class="iconfont mima"
				    class-prefix="icon"
				    name="ziyuanxhdpi"
				  />
				</template>
              </van-field>
              <van-field
                v-model="form.invite_code"
                name="invite_code"
                :placeholder="$t('PleaseEnterTheInvitationCode')"
                :rules="invirules"
                maxlength="6"
                class="newInput"
              >
				<template #left-icon>
				  <van-icon
				    class="iconfont yaoqing"
				    class-prefix="icon"
				    name="zengjiarenwu"
				  />
				</template>
              </van-field>
            </van-form>
          </van-row>
        </van-tab>
        <!--      <van-tab :title=" $t('register.yonghuming')">
          <van-row>
            <van-form ref="nameform">
              <van-cell-group>
                <van-field
                  v-model="form.username"
                  name="username"
                  clearable
                  :placeholder="$t('login.username')"
                  :rules="usernamerules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="yonghuming"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  name="password"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="passrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.repassword"
                  :type="type"
                  name="repassword"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="repassrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
              </van-cell-group>
            </van-form>
          </van-row>
        </van-tab> -->
      </van-tabs>
      <van-button
        v-if="
          form.username && form.password && form.repassword && form.invite_code
        "
        class="mybtnTo"
        :loading="loading"
        :loading-text="$t('register.registering')"
        @click="register"
      >
        {{ $t("register.quickreg") }}
      </van-button>
      <van-button
        class="mybtn"
        v-else
        :loading="loading"
        :loading-text="$t('register.registering')"
      >
        {{ $t("register.quickreg") }}
      </van-button>
    </div>
	<div class="footer">
		<span class="gray">{{ $t("register.yizhuce") }}？</span><span class="pointer" @click="login()">{{ $t("register.qudenglu") }}</span>
	</div> 
    <!-- <van-row class="agreement">
      <van-checkbox v-model="checked" shape="square"
        >{{ $t('register.consent') }}
      </van-checkbox>
      <span style="color:#004ea3" @click="agreement">
        {{ $t('register.agreement') }}
      </span>
    </van-row> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      appLogo: "",
      appName: "",
      lang: localStorage.getItem('lang'),
	  select_quhao:'+86',
	  guoqi:'https://jk53y.com/Uploads//wallet/2023-07-23/64bccf0adb92f.jpg',
      option: [],
      form: {
        type: 1,
        username: "",
        password: "",
        repassword: "",
        invite_code: "",
        sms: "",
      },
      type: "password",
      loading: false,
      checked: false,
      eye: "closed-eye",
      sendsmsbtn: true,
      sendsmstime: 60,
      sendemailbtn: true,
      sendemailtime: 60,
      // 手机号验证
      phonerules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return /^[0-9]*$/.test(value);
          },
          message: this.$t('WrongPhoneNumber'),
          trigger: "onBlur",
        },
      ],
      //验证码验证
      smsrules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return /^[0-9]*$/.test(value);
          },
          message: this.$t("PleaseEnterCorrectVerficationCode"),
          trigger: "onBlur",
        },
      ],
      // 用户名验证
      usernamerules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return /^([a-zA-Z]|[0-9])*$/.test(value);
          },
          message: this.$t("register.yhmgs"),
          trigger: "onBlur",
        },
      ],
      // 邮箱验证
      emailrules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return /^([a-zA-Z]|[0-9_.-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
              value
            );
          },
          message: this.$t("register.emailformat"),
          trigger: "onBlur",
        },
      ],
      // 密码验证
      passrules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
      ],
      // 重复密码
      repassrules: [
        {
          validator: (value) => {
            return this.form.password === value;
          },
          message: this.$t("register.eqpass"),
          trigger: "onBlur",
        },
      ],
      // 邀请码验证
      invirules: [
        {
          required: true,
          message: this.$t("register.required"),
          trigger: "onBlur",
        },
      ],
    };
  },
  created() {
    this.getInvite();
    this.getinfo();
    this.getLogo();
  },
  methods: {
	handelChange(item){
		this.select_quhao = item.text;
		this.$refs.item.toggle();
		// let that = this;
		// this.option.forEach(item =>{
		// 	if(item.value == value){
		// 		that.guoqi = item.icon;
		// 		//console.log("that.lang",that.lang,'that.quoqi',that.guoqi)
		// 	}
		// })
	},
    getInvite() {
      var pams = window.location.search;
      var ll = pams.startsWith("?lang=zh&invite=");
      var ss = pams.replace("?lang=zh&invite=", "");
      if (ll) {
        this.form.invite_code = ss;
      }
    },
    async getLogo() {
      const { data } = await this.$http.get("/home/index/getConf");
      if (data) {
        if (data.code === 200) {
          this.appLogo = data.data.imageurl;
          this.appName = data.data.sitename;
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get("/home/index/getcode");
      if (data) {
        if (data.code === 200) {
          this.option = data.data;
		  this.select_quhao = this.option[0].text;
        }
      }
    },
    // 查看密码
    openeye(event) {
      if (this.type === "password") {
        this.eye = "eye";
        this.type = "text";
      } else {
        this.eye = "closed-eye";
        this.type = "password";
      }
    },
    // 手机号找回
    phone() {
      this.loading = true;
    },
    async sendsms() {
      if (!this.form.username) {
        this.$toast.fail(this.$t("forget.enterphone"));
        return false;
      }
      if (this.sendsmsbtn === false) {
        return false;
      }
      const { data } = await this.$http.post("/home/index/sendsms", {
        phone: this.form.username,
        area: this.select_quhao,
		//area:'+86',
      });
      if (data) {
        if (data.code === 200) {
          this.sendsmsbtn = false;
          this.sendsmstime = 60;
          this.$toast.success(this.$t("forget.codesent"));
          const smsTimer = setInterval(() => {
            this.sendsmstime--;
            if (this.sendsmstime <= 0) {
              this.sendsmsbtn = true;
              clearInterval(smsTimer);
            }
          }, 1000);
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
    },
    // 邮箱注册验证码
    email() {
      this.emailloading = true;
    },
    async sendemail() {
      if (!this.form.username) {
        this.$toast.fail(this.$t("forget.enteremail"));
        return false;
      }
      if (
        !/^([a-zA-Z]|[0-9_.-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
          this.form.username
        )
      ) {
        this.$toast.fail(this.$t("forget.emailformat"));
        return false;
      }
      if (this.sendemailbtn === false) {
        return false;
      }
      const { data } = await this.$http.post("/home/index/Registeremail", {
        email: this.form.username,
      });
      if (data) {
        if (data.code === 200) {
          this.sendemailbtn = false;
          this.sendemailtime = 60;
          this.$toast.success(this.$t("forget.codesent"));
          const eaminTimer = setInterval(() => {
            this.sendemailtime--;
            if (this.sendemailtime <= 0) {
              this.sendemailbtn = true;
              clearInterval(eaminTimer);
            }
          }, 1000);
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
    },
    // 快速注册
    register() {
      // 服务条款
      // if (!this.checked) {
      //   this.$toast.fail(this.$t("register.readagreement"));
      //   return false;
      // }
      if (this.form.type === 0) {
          this.$refs.phoneform
            .validate()
            .then(async () => {
              //  验证通过
              this.loading = true
              const { data } = await this.$http.post(
                '/home/index/register',
                this.form
              )
              if (data) {
                if (data.code === 200) {
                  this.$toast.success(
                    this.getlang(data.msg, localStorage.getItem('lang'))
                  )
                  this.$router.push('/login')
                } else {
                  this.$toast.fail(
                    this.getlang(data.msg, localStorage.getItem('lang'))
                  )
                }
              }
              this.loading = false
            })
            .catch(() => {
              // 验证失败
              return false
            })
        } 
		// else if (this.form.type === 0) {
  //         this.$refs.nameform
  //           .validate()
  //           .then(async () => {
  //             this.loading = true
  //             console.log(this.form)
  //             const { data } = await this.$http.post(
  //               '/home/index/register',
  //               this.form
  //             )
  //             if (data) {
  //               if (data.code === 200) {
  //                 this.$toast.success(
  //                   this.getlang(data.msg, localStorage.getItem('lang'))
  //                 )
  //                 this.$router.push('/login')
  //               } else {
  //                 this.$toast.fail(
  //                   this.getlang(data.msg, localStorage.getItem('lang'))
  //                 )
  //               }
  //             }
  //             this.loading = false
  //           })
  //           .catch(() => {
  //             // 验证失败
  //             return false
  //           })
  //       }
		 else {
        this.$refs.emailform
          .validate()
          .then(async () => {
            this.loading = true;
            const { data } = await this.$http.post(
              "/home/index/register",
              this.form
            );
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem("lang"))
                );
                this.$router.push("/login");
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem("lang"))
                );
              }
            }
            this.loading = false;
          })
          .catch(() => {
            // 验证失败
            return false;
          });
      }
    },
    // 服务条款
    agreement() {
      this.$router.push({ path: "/terms" });
    },
    // 密码一致校验
    checkpass() {
      return this.form.password === this.form.repassword;
    },
	login(){
		this.$router.push('/login')
	},
  },
};
</script>

<style lang="less" scoped>
.van-cell:after {
  border-bottom: 0;
}
/deep/ .van-cell {
  background-color: #00000000;
}
/deep/ .van-tabs__nav {
  background-color: #00000000;
}

/deep/ .van-field__control {
  color: #333;
  &::placeholder {
    color: #999;
  }
}
.loginbox {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #fff; //注册页背景
  background-size: cover;
  position: relative;
  padding: 2.75rem 2rem 0 2rem;
  .back-icon {
    position: fixed;
    left: 4%;
    top: 2%;
    .van-icon {
      color: #fff; //后退键
    }
  }

  .loginBody-image {
    margin-top: 20%;
    height: 20%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .spanTv {
      font-family: "Alibaba PuHuiTi";
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      color: #fff;
      margin-top: 0.5rem;
      margin-left: 1rem;
	  color:#333;
    }
  }
  .loginBody-found {
    margin-left: 5%;
    width: 90%;
    padding-bottom: 3%;
    margin-top: 10px;
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1.5rem;
      background: transparent !important;
    }
    /deep/.van-tab {
      font-size: 1rem;
    }
    /deep/.van-tab--active {
      color: #333;
      font-weight: 500;
	  border-bottom:2px solid #f0b90b;
    }
    .van-field {
      padding: 20px 0 10px 0;
      font-size: 1rem;
    }
    /deep/.van-field__label {
      width: auto;
    }
    /deep/ .van-icon,
    /deep/.van-field__right-icon {
      color: #fff;
      font-size: 1.2rem;
    }
    .van-tab {
      font-size: 1rem;
    }
    .van-tab--active {
      color: #333;
    }
    /deep/.van-tab__text--ellipsis {
      // color: #004ea3;//选项字体
	  -webkit-box-orient:horizontal;
    }
    .van-icon-clear {
      color: #b3b3b3;
      font-size: 1rem;
    }
    .van-field__control {
      padding-left: 5px;
    }

    .mybtnTo {
      margin-top: 18%;
      width: 100%;
      height: 2.66667rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #f0b90b;
      border-radius: 0.4rem;
      border: none;
    }

    .mybtn {
      margin-top: 18%;
      width: 100%;
      height: 2.66667rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #f2d169;
      border-radius: 0.4rem;
      border: none;
    }
  }

  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .agreement {
    margin-top: 15%;
    padding-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    /deep/.van-icon {
      height: 1rem;
      width: 1rem;
      font-size: 0.66667rem;
      color: #fff !important;
      border-radius: 4px;
    }
    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }
    .van-checkbox__icon--checked .van-icon {
      color: #fff !important;
      background-color: #004ea3;
      border-color: #004ea3;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
  .iconfont {
    color: #f0b90b;
    font-size: 18px;
  }
  .van-cell-group{ background-color:#000;}
  .newInput{ padding-top: 14px; color:#333;  border-bottom: 0.5px solid #E8F2FE; background-color: #fff;}
}
/deep/.van-dropdown-menu{ min-width:60px;}
.footer{ line-height: 48px; text-align: center; color:#FFF; font-size: 14px;
	.gray{ color:gray;}
}
.guoqi{ width: 24px; height:20px; display: inline-block; vertical-align:-2px;}
.shouji{
	&::before{ font-size: 22px; margin-left: -3px; margin-right: 5px;}
}
.yanzheng{
	&::before{ font-size: 24px;margin-left: -2px;}
}
.yaoqing{
	&::before{ font-size: 28px;}
}
.mima{
	&::before{ margin-right:5px;}
}
.pointer{color:#333;}
.smsbtn{ border-bottom:1px solid #E8F2FE;}
.list{ line-height: 48px;border-bottom:1px solid #E8F2FE;
	.image{ width: 24px; height:20px; margin:0 10px; display: inline-block; vertical-align: middle;}
	.guojia{ margin-right: 10px;}
}
.selectItem{ color:blue}
.selectBlock{ display: inline-block; position:relative; width: 80px;}
.menuStyle{ position: absolute; top:0;right:0;}
</style>
